import { Product } from "./product"

export class ProductCategory {
    category: string
    products!: Product[]
    selected?: boolean

    constructor(category: string) {
        this.category = category;
        this.products = [];
        this.selected = false
    }

}