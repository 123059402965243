import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProposerComponent } from './proposer.component';
import { ProposerGuardCanActivate } from './guards/proposal.guard';

const routes: Routes = [
    {
        path: '', component: ProposerComponent,
        canActivate: [ProposerGuardCanActivate],
        title: 'Recommendation',
        canDeactivate: [],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProposerRoutingModule { }

