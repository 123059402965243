import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './error/unauthorized/unauthorized.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { AestheticsComponent } from './aesthetics/aesthetics.component';
import { AuthComponent } from './auth/auth.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'proposer', pathMatch: 'full'
  },
  {
    path: 'pdf', component: PdfViewerComponent, title: 'Fotofinder'
  },
  {
    path: 'proposer', component: AestheticsComponent,
    children: [
      {
        path: '', component: AuthComponent, pathMatch: 'full', title: 'Authentication'
      },
      {
        path: 'recommendation', loadChildren: () => import('./proposer/proposer.module').then(m => m.ProposerModule)
      }
    ]
  },
  {
    path: 'unauthorized', pathMatch: 'full', component: UnauthorizedComponent, title: 'Unauthorized'
  },
  {
    path: 'notfound', component: PageNotFoundComponent, title: 'Not Found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
