<div class="flex gap-2 row-start-1 row-end-2 col-start-1 col-end-2"><!-- Treatments -->
  <div *ngIf="isHub" class="flex w-44 shrink-0 h-full flex-col gap-2">
      <!-- <ng-select [(ngModel)]="filterMode" [clearable]="false" [searchable]="false">
          <ng-option *ngFor="let btn of filterButtons; index as index" [value]="index">{{btn.name}}</ng-option>
      </ng-select> -->
      <app-treatment-bar #treatementBar></app-treatment-bar>
  </div>
  <!-- Canvas part-->
  <div class="h-full w-full flex portrait:flex-col bg-ffgray rounded-lg">
      <div class="relative h-full w-full flex flex-col gap-2">
          <div class="cross-input" *ngIf="isCrossOrPoint" [style.top]="ruler.top">
            <div class="unit-input">
              <!-- <div class="input-wrapper">
                  <!-- <input type="range" id="unitsIn" min="0" max="1" step="1" [(ngModel)]="unit"
                      (input)="onChangeUnit()"> -->
              <!-- <mat-slider id="unitsIn" min="0" max="1" step="1">
                      <input  matSliderThumb [(ngModel)]="unit" (input)="onChangeUnit()">
                  </mat-slider> -->
              <!-- </div> -->
              <mat-slide-toggle [(ngModel)]="unit" (change)="onChangeUnit()" color="#893681" [hideIcon]="true"
                                id="unitsIn"></mat-slide-toggle>

              <div class="unit-label">
                <p class="m-0">U</p>
                <p class="m-0">ML</p>
              </div>
            </div>
            <div class="value-input">
              <div class="input-wrapper unit" *ngIf="!unit; else isMl">
                <!-- <input type="range" id="valueUnitIn" min="0" max="32" [step]="1" [(ngModel)]="currentUnitValue"
                (input)="onChangeUnitValue()"> -->
                <mat-slider id="valueUnitIn" min="0" max="32" [step]="currentUnitValue >= 20? 2:1">
                  <input matSliderThumb [(ngModel)]="currentUnitValue"
                         (valueChange)="onChangeUnitValue()">
                </mat-slider>
                <div class="ruler-wrapper">
                  <ng-container *ngFor="let x of unitsValues; index as i">
                    <div class="ruler" [style.width]="'3.125%'"
                         *ngIf="x % 5 === 0 && x <= 20; else moreThan20">
                      <div class="ruler-long-mark"></div>
                      <p>{{x}}</p>
                    </div>
                    <ng-template #moreThan20>
                      <div class="ruler" [style.width]="'3.125%'"
                           *ngIf="x > 20 && (x | unitInput) % 10 === 0; else shortMark">
                        <div class="ruler-long-mark"></div>
                        <p>{{x | unitInput}}</p>
                      </div>
                    </ng-template>
                    <ng-template #shortMark>
                      <div class="ruler" [style.width]="'3.125%'"
                           *ngIf="x < 20 || (x > 20 && (x | unitInput) % 5 === 0); else noMark">
                        <div class="ruler-short-mark"></div>
                        <p></p>
                      </div>
                    </ng-template>
                    <ng-template #noMark>
                      <div class="ruler" [style.width]="'3.125%'">
                        <div class="ruler-no-mark"></div>
                        <p></p>
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
              <ng-template #isMl>
                <div class="input-wrapper ml">
                  <!-- <input type="range" id="valueMlIn" min="0" max="23" step="1" [step]="1"
                  [(ngModel)]="currentMlValue" (input)="onChangeMlValue(0)"> -->
                  <mat-slider id="valueUnitIn" min="0" max="23" step="1">
                    <input matSliderThumb [(value)]="currentMlValue"
                           (valueChange)="onChangeMlValue()">
                  </mat-slider>
                  <div class="ruler-wrapper">
                    <ng-container *ngFor="let x of mlValues; index as i">
                      <div class="ruler" [style.width]="100/23 + '%'"
                           *ngIf="((x|mlInput)<=1 && (x|mlInput)%0.5===0) || ((x|mlInput)===5 || (x|mlInput)===10); else noMark">
                        <div class="ruler-long-mark"></div>
                        <p>{{x | mlInput}}</p>
                      </div>
                      <ng-template #noMark>
                        <div class="ruler" [style.width]="100/23 + '%'">
                          <div class="ruler-short-mark"></div>
                          <p></p>
                        </div>
                      </ng-template>
                    </ng-container>
                  </div>
                </div>
              </ng-template>
            </div>
            <div class="icon">
              <i *ngIf="!ruler.isTop" class="fa-sharp fa-solid fa-arrow-up"
                 style="font-size: 25px; cursor: pointer;" (click)="onRulerFlip()"></i>
              <i *ngIf="ruler.isTop" class="fa-sharp fa-solid fa-arrow-down"
                 style="font-size: 25px; cursor: pointer;" (click)="onRulerFlip()"></i>
            </div>
        </div>
          <div #canvasBound class="editor-container slider-wrapper rounded-lg"
              (mouseleave)="onContainerMouseLeave($event)" (mouseup)="onContainerMouseUp($event)"
              (mousemove)="onContainerMouseMove($event)" (touchend)="onContainerTouchEnd()"
              (touchmove)="onContainerTouchMove($event)">
              <app-canvas id="drawingCanvas" #canvas [(isImageLoaded)]="isImageLoaded"
                  (disableDrawing)="onSelectTool($event, 0, 0)" [drawingColor]="drawingColor" [fontSize]="fontSize"
                  [lineSize]="lineWidth" [highlightSize]="highlightWidth" [highlightOpacity]="highlightOpacity"
                  [injectionsRadius]="injectionsRadius"></app-canvas>
              <div *ngIf="filterMode > 0" class="slider" (touchstart)="onSliderTouchStart($event)"
                  (mousedown)="onSliderMouseDown($event)"
                  [style.left]="'min(' + sliderLeft + '%' + ', calc(100% - 3px))'">
                  <span>
                      <i class="fa-sharp fa-solid fa-less-than"></i>
                      <i class="fa-sharp fa-solid fa-greater-than"></i>
                  </span>
              </div>
              <div class="loading" *ngIf="isError">
                  <i class="fa-sharp fa-solid fa-rotate-right" id="tryAgain" (click)="retry.emit(null)"></i>
                  <h3>{{ "imageEditor.tryAgain" | translate }}</h3>
              </div>
              <ngx-spinner color="rgba(137,54,129,1)" bdColor="#262b31" type="ball-clip-rotate" [fullScreen]="false"
                  name="filter-spinner" [zIndex]="1050">
              </ngx-spinner>
          </div>
      </div>
  </div>
</div>
<!-- Toolbar -->
<overlay-scrollbars [defer]="true" [ngStyle]="{ 'overflow-x': (innerWidth > innerHeight) ? 'auto' : 'hidden', 'overflow-y': (innerWidth <= innerHeight) ? 'auto' : 'hidden' }">
  <div class="flex flex-col portrait:flex-row gap-3 landscape:pr-1 portrait:w-full portrait:h-36 shrink-0 text-center text-xs
  text-white py-2 portrait:px-2"
       [ngClass]="toolbarExtendClasses">
    <!-- FACE Simulation -->
    <ng-container *ngIf="isFaceEnabled">
      <div class="landscape:w-full portrait:h-full flex flex-col items-center gap-1 cursor-pointer min-w-fit justify-center"
           (click)="onFaceSimulation()">
        <img src="/assets/img/svg/drawings/face.svg" alt="">
        <div class="text-xs">{{ "imageEditor.faceSimulation" | translate }}</div>
      </div>
      <div class="h-px w-full portrait:h-full portrait:w-px bg-white shrink-0"></div>
    </ng-container>
    <!-- Injection -->
    <div
      class="landscape:w-full portrait:h-full flex flex-col flex-wrap portrait:flex-nowrap items-center gap-2 min-w-fit">
      <div class="w-full text-center">{{ "imageEditor.injections" | translate }}</div>
      <div class="grid landscape:grid-cols-2 portrait:grid-rows-2 portrait:grid-flow-col">
        <div class="item selectable" (click)="onSelectTool($event, 2, 1)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/cross.svg" alt="">
          <div> {{ "imageEditor.cross" | translate }}</div>
        </div>
        <div class="item selectable" (click)="onSelectTool($event, 2, 0)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/point.svg" alt="">
          <div> {{ "imageEditor.point" | translate }}</div>
        </div>
      </div>
    </div>
    <div class="h-px w-full portrait:h-full portrait:w-px bg-white shrink-0"></div>
    <!-- Selection -->
    <div
      class="landscape:w-full portrait:h-full flex flex-col flex-wrap portrait:flex-nowrap items-center gap-2 shrink-0">
      <div class="w-full text-xs">{{ "imageEditor.selection" | translate }}</div>
      <div class="grid landscape:grid-cols-2 portrait:grid-rows-2 portrait:grid-flow-col">
        <div class="item selectable" (click)="onSelectTool($event, 0, 0)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/single.svg" alt="">
          <div> {{ "imageEditor.single" | translate }}</div>
        </div>
        <div class="item selectable" (click)="onSelectTool($event, 0, 1)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/multiple.svg" alt="">
          <div> {{ "imageEditor.multiple" | translate }}</div>
        </div>
      </div>
    </div>
    <div class="h-px w-full portrait:h-full portrait:w-px bg-white shrink-0"></div>
    <!-- Drawing tools -->
    <div
      class="landscape:w-full portrait:h-full flex flex-col flex-wrap portrait:flex-nowrap items-center gap-2 shrink-0">
      <div class="w-full">{{ "imageEditor.drawingTools" | translate }}</div>
      <div class="grid landscape:grid-cols-2 portrait:grid-rows-2 portrait:grid-flow-col">
        <div class="item selectable" (click)="onSelectTool($event, 1, 0)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/highlight.svg" alt="">
          <div> {{ "imageEditor.highlight" | translate }}</div>
        </div>
        <div class="item selectable" (click)="onSelectTool($event, 1, 1)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/arrow.svg" alt="">
          <div> {{ "imageEditor.arrow" | translate }}</div>
        </div>
        <div class="item selectable" (click)="onSelectTool($event, 1, 2)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/free.svg" alt="">
          <div> {{ "imageEditor.free" | translate }}</div>
        </div>
        <div class="item selectable" (click)="onSelectTool($event, 1, 3)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/area.svg" alt="">
          <div> {{ "imageEditor.area" | translate }}</div>
        </div>
        <div class="item selectable" (click)="onSelectTool($event, 1, 4)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/bar.svg" alt="">
          <div> {{ "imageEditor.bar" | translate }}</div>
        </div>
        <div class="item selectable" (click)="onSelectTool($event, 1, 5)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/circle.svg" alt="">
          <div> {{ "imageEditor.circle" | translate }}</div>
        </div>
        <div class="item selectable" (click)="onSelectTool($event, 1, 7)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/text.svg" alt="">
          <div> {{ "imageEditor.text" | translate }}</div>
        </div>
      </div>
    </div>
    <div class="h-px w-full portrait:h-full portrait:w-px bg-white shrink-0"></div>
    <!-- Manipulation -->
    <div
      class="landscape:w-full portrait:h-full flex flex-col items-center justify-center gap-2 shrink-0">
      <div class="w-full">{{ "imageEditor.manipulation" | translate }}</div>
      <div class="grid landscape:grid-cols-2 portrait:grid-rows-2 portrait:grid-flow-col">
        <div class="item selectable" (click)="onSelectTool($event, 1, 6)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/crop.svg" alt="">
          <div> {{ "imageEditor.crop" | translate }}</div>
        </div>
        <div class="item selectable" (click)="onSelectTool($event, 2, 2)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/move.svg" alt="">
          <div> {{ "imageEditor.move" | translate }}</div>
        </div>
        <div class="item" *ngIf="!hasBackground" (click)="onToggleBackground(true)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/background.svg" alt="">
          <div> {{ "imageEditor.recover" | translate }}</div>
        </div>
        <div class="item relative" [class.pointer-events-none]="isProcessingBackground" *ngIf="hasBackground" (click)="onToggleBackground(false)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/background.svg" alt="">
          <div> {{ "imageEditor.background" | translate }}</div>
          <ngx-spinner size="small" bdColor="#0c1014" color="rgba(137,54,129,1)" type="ball-clip-rotate" [fullScreen]="false"
            name="background" [zIndex]="1050">
          </ngx-spinner>
        </div>
        <div class="item" *ngIf="canvas.isCropped" (click)="onUncrop()">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/delete.svg" alt="">
          <div> {{ "imageEditor.unCrop" | translate }}</div>
        </div>
      </div>
    </div>
    <div class="h-px w-full portrait:h-full portrait:w-px bg-white shrink-0"></div>
    <!-- Settings -->
    <div class="landscape:w-full portrait:h-full flex flex-col items-center gap-2 shrink-0">
      <div class="w-full">{{ "imageEditor.settings" | translate}}</div>
      <div class="grid landscape:grid-cols-2 portrait:grid-rows-2 portrait:grid-flow-col">
        <div class="item" (click)="onUnRedo(0)"
             *ngIf="canvas.undo.length > 0 && !canvas.isCropping; else undoNotActive">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/undo.svg" alt="">
          <div> {{ "imageEditor.undo" | translate }}</div>
        </div>
        <ng-template #undoNotActive>
          <div class="item">
            <img class="w-4 h-4" src="/assets/img/svg/drawings/undo-gray.svg" alt="">
            <div> {{ "imageEditor.undo" | translate }}</div>
          </div>
        </ng-template>
        <div class="item" (click)="onUnRedo(1)"
             *ngIf="canvas.redo.length > 0 && !canvas.isCropping; else redoNotActive">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/redo.svg" alt="">
          <div> {{ "imageEditor.redo" | translate }}</div>
        </div>
        <ng-template #redoNotActive>
          <div class="item">
            <img class="w-4 h-4" src="/assets/img/svg/drawings/redo-gray.svg" alt="">
            <div> {{ "imageEditor.redo" | translate }}</div>
          </div>
        </ng-template>
        <div class="item" (click)="onTriggerDelete()">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/delete.svg" alt="">
          <div> {{ "imageEditor.delete" | translate}}</div>
        </div>
        <div class="item" (click)="onToggleSettingsTab(true)">
          <img class="w-4 h-4" src="/assets/img/svg/drawings/settings.svg" alt="">
          <div>{{"imageEditor.settings" | translate}}</div>
        </div>
      </div>
    </div>
  </div>
</overlay-scrollbars>


<div class="fixed z-[1010] right-0 top-0 text-sm text-white max-h-full w-full sm:w-70 h-full sm:h-auto bg-ffgray1 border border-ffgray1 flex flex-col gap-2"
  *ngIf="isSettingsTabShown">
  <div class="font-semibold p-4 justify-center text-base sm:text-xl items-center flex"> {{ "imageEditor.settingsEditTools"
      | translate }}</div>
  <div class="bg-ffblack flex flex-col overflow-auto">
      <div class="flex flex-col p-4">
          <div class="flex flex-col gap-1 relative">
              <p class="absolute top-2"> {{ "imageEditor.lineWidth" | translate }} {{lineWidth}}</p>
              <div class="flex gap-4 items-center">
                  <mat-slider class="w-full" min="2" max="10" step="1" discrete [displayWith]="formatLabel">
                      <input matSliderThumb [(value)]="lineWidth">
                  </mat-slider>
                  <svg height="100" width="100">
                      <line x1="0" y1="50" x2="100" y2="50" [attr.stroke]="drawingColor"
                          [style.stroke-width]="lineWidth" />
                  </svg>
                  <!-- <div class="additional"><i class="fa-solid fa-plus"></i></div> -->
              </div>
          </div>
          <div class="flex flex-col gap-1 relative">
              <p class="absolute top-2"> {{ "imageEditor.fontSize" | translate}} {{fontSize}}</p>
              <div class="flex gap-4 items-center">
                  <mat-slider class="w-full" min="12" max="48" step="1" discrete [displayWith]="formatLabel">
                      <input matSliderThumb [(value)]="fontSize">
                  </mat-slider>
                  <svg width="100" height="100">
                      <!--   Circle -->
                      <g transform="translate(30,50)">
                          <circle cx="0" cy="0" r="30" stroke="rgba(0, 0, 0, 0)" stroke-width="2"
                              fill="rgba(0, 0, 0, 0)">
                          </circle>
                          <text x="0" y="0" alignment-baseline="middle" [attr.font-size]="fontSize" stroke-width="0"
                              [attr.fill]="drawingColor" text-anchor="middle">A</text>
                      </g>
                  </svg>
                  <!-- <div class="additional"><i class="fa-solid fa-plus"></i></div> -->
              </div>
          </div>
          <div class="flex flex-col gap-1 relative">
              <p class="absolute top-2"> {{ "imageEditor.highlightWidth" | translate }} {{highlightWidth}}</p>
              <div class="flex gap-4 items-center">
                  <mat-slider class="w-full" min="20" max="60" step="5" discrete [displayWith]="formatLabel">
                      <input matSliderThumb [(value)]="highlightWidth">
                  </mat-slider>
                  <svg height="100" width="100">
                      <circle cx="30" cy="50" [attr.r]="highlightWidth/2" stroke="black" stroke-width="0"
                          [attr.fill]="drawingColor" />
                  </svg>
                  <!-- <div class="additional"><i class="fa-solid fa-plus"></i></div> -->
              </div>
          </div>
          <div class="flex flex-col gap-1 relative">
              <p class="absolute top-2"> {{ "imageEditor.highlightOpacity" | translate }} {{highlightOpacity}}</p>
              <div class="flex gap-4 items-center">
                  <mat-slider class="w-full" min="0.1" max="1" step="0.1" discrete [displayWith]="formatLabel">
                      <input matSliderThumb [(value)]="highlightOpacity">
                  </mat-slider>
                  <svg height="100" width="100">
                      <circle cx="30" cy="50" r="30" stroke="black" stroke-width="0" [attr.fill]="drawingColor"
                          [style.opacity]="highlightOpacity" />
                  </svg>
                  <!-- <div class="additional"><i class="fa-solid fa-plus"></i></div> -->
              </div>
          </div>
          <div class="flex flex-col gap-1 relative">
              <p class="absolute top-2"> {{ "imageEditor.injections" | translate }} {{injectionsRadius}}</p>
              <div class="flex gap-4 items-center">
                  <mat-slider class="w-full" min="4" max="30" step="2" discrete [displayWith]="formatLabel">
                      <input matSliderThumb [(value)]="injectionsRadius">
                  </mat-slider>
                  <svg height="100" width="100">
                      <circle cx="30" cy="50" [attr.r]="injectionsRadius/2" stroke="black" stroke-width="0"
                          [attr.fill]="drawingColor" />
                  </svg>
                  <!-- <div class="additional"><i class="fa-solid fa-plus"></i></div> -->
              </div>
          </div>
          <div class="flex gap-4 items-center">
              <p class="text-nowrap"> {{ "imageEditor.selectColor" | translate }}</p>
              <div class="w-full flex gap-2 items-center justify-center">
                  <div [cpPosition]="'top-left'" [cpPositionOffset]="'50%'" [cpPositionRelativeToArrow]="true"
                      [(colorPicker)]="drawingColor" [cpOutputFormat]="'hex'" [style.background-color]="drawingColor"
                      style="width: 50px; height: 50px; border-radius: 50%; cursor: pointer;;"></div>
                  <!-- <div class="additional"><i class="fa-solid fa-plus"></i></div> -->
              </div>
          </div>
      </div>
      <div class="flex justify-center items-center border-t py-4 border-t-ffgray1">
          <button type="button" class="rounded-lg bg-ffred font-semibold py-2 px-8"
              (click)="onToggleSettingsTab(false); saveSettings()"> {{ "imageEditor.close" | translate }}</button>
      </div>
  </div>
</div>
<img class="hidden" crossorigin="anonymous" #imgFilter>
<canvas #canvasFilter class="hidden"></canvas>
