import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { DataService } from '../shared/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements AfterViewInit {
  @ViewChild('pdfViewer') pdfViewer!: PdfJsViewerComponent

  constructor(private _dataService: DataService, private _route: ActivatedRoute, private _router: Router) {}

  ngAfterViewInit(): void {
    const uuid = this._route.snapshot.queryParams['uuid']
    if(uuid) {
      this._openPdf(uuid)
    } else {
      this._router.navigate(['/notfound'])
    }
  }

  private _openPdf(uuid: string) {
    this._dataService.getPdf(uuid).subscribe(r => {
      this.pdfViewer.pdfSrc = encodeURIComponent(r.url)
      this.pdfViewer.refresh()
    })
  }
}
