import {DrawingUtils, FaceLandmarker, FaceLandmarkerResult, FilesetResolver,} from '@mediapipe/tasks-vision';
import {Injectable} from '@angular/core';
import {ImgWarper} from '../img-warper/ImgWarper';
import {CroppedImage} from '../types/CroppedImage';
import {Point} from '../types/points';


interface Text {
  text: string;
  x: number;
  y: number;
}

const LIPS_LANDMARK_IDS2 = [13, 14, 80, 81, 82, 312, 311, 310, 87, 317, 86, 316, 15, 42, 272, 184, 408, 40, 39, 37, 0, 267, 269, 270]
const rightEyesBrow_LANDMARK_IDS = [124, 113, 130, 247, 30, 29, 27, 28, 56, 190, 25, 33, 246, 161, 160, 159, 158, 157, 173, 243]
const leftEyesBrow_LANDMARK_IDS = [353, 342, 359, 467, 260, 259, 257, 258, 286, 414, 225, 263, 466, 388, 387, 386, 385, 384, 398, 463]
@Injectable({
  providedIn: 'root'
})
export class FaceSimulationService {

  private _faceLandmarkerResult!: FaceLandmarkerResult;
  private _image!: HTMLImageElement;
  private _ctx2D!: any;
  private _canvas!: HTMLCanvasElement;
  private _horizontalCanvas!: HTMLCanvasElement;
  private _verticalCanvas!: HTMLCanvasElement;
  private _originalImageCanvas!: HTMLCanvasElement;
  // Ảnh crop môi
  private _croppedLipsImg!: CroppedImage;
  // Ảnh đã chỉnh 1 phần môi
  private _preCroppedLipsImg!: CroppedImage;
  private _croppedLeftEyeBrowImg!: CroppedImage;
  private _croppedRightEyeBrowImg!: CroppedImage;
  private _lipsCanvas!: HTMLCanvasElement;

  private _upperVal = 0;
  private _lowerVal = 0;
  private _eyesBrowVal = 0;
  private drewHorizontal = false;
  private drewVertical = false;

  private _normalizePoint(points: Point[], dx: number, dy: number) {
    const rs: Point[] = []
    for (const point of points) {
      const x = point.x - dx
      const y = point.y - dy
      const z = point.z
      rs.push(new Point(x, y, z))
    }
    return rs
  }

  private _updatePointY(point: Point, sy: number) {
    const y = point.y + sy;
    return new Point(point.x, y, point.z)
  }

  private _processLips(value: number, croppedLipsImage: CroppedImage, upper: boolean) {
    const canvas = this._createNewCanvas(croppedLipsImage.croppedImageData, 'test')
    this._image.parentNode?.appendChild(canvas)
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

    ctx.putImageData(croppedLipsImage.croppedImageData, 0, 0)

    const warper = new ImgWarper.PointDefiner(canvas, null, croppedLipsImage.croppedImageData)
    const lipsLandmarks = this._normalizePoint(this._getPointsByLandmarkIds(LIPS_LANDMARK_IDS2), croppedLipsImage.startPoint.x, croppedLipsImage.startPoint.y)

    const points = this._normalizePoint(this._createBoundaryControlPoint(croppedLipsImage.startPoint, croppedLipsImage.croppedImageData.width, croppedLipsImage.croppedImageData.height), croppedLipsImage.startPoint.x, croppedLipsImage.startPoint.y)

    lipsLandmarks.push(...points)

    // warper.lift(lipsLandmarks, lipsLandmarks);

    //calculate highest and lowest lips point
    const upL = this._getPointsByLandmarkIds([0, 13, 37, 267])
    const loL = this._getPointsByLandmarkIds([14, 17])

    //calculate upplip and lowerlip thick
    const h1 = Math.abs(upL[0].y - upL[1].y)
    const h2 = Math.abs(loL[0].y - loL[1].y)

    const cp_points = lipsLandmarks.map((point: Point) => {
      if (upper) {
        if (point.z === 40) return this._updatePointY(point, -value * 0.2 * h1) // 0.5 -> 0.8
        else if (point.z === 39) return this._updatePointY(point, -value * 0.2 * h1) // 0.5 -> 0.8
        else if (point.z === 37) return this._updatePointY(point, -value * 0.25 * h1)// 0.4 -> 0.7
        else if (point.z === 0) return this._updatePointY(point, -value * 0.25 * h1) // 0.5 -> 0.8
        else if (point.z === 267) return this._updatePointY(point, -value * 0.25 * h1)// 0.4 -> 0.7
        else if (point.z === 269) return this._updatePointY(point, -value * 0.2 * h1) // 0.5 -> 0.8
        else if (point.z === 270) return this._updatePointY(point, -value * 0.2 * h1)// 0.4 -> 0.7
        else if (point.z === 184) return this._updatePointY(point, -value * 0.2 * h1)// 0.4 -> 0.7
        else if (point.z === 408) return this._updatePointY(point, -value * 0.2 * h1)// 0.4 -> 0.7
        else return point

      } else {
        if (point.z === 86) return this._updatePointY(point, value * 0.2 * h2)
        else if (point.z === 316) return this._updatePointY(point, value * 0.2 * h2)
        else if (point.z === 15) return this._updatePointY(point, value * 0.3 * h2)
        else return point
      }
    });



    const newImg = warper.lift(lipsLandmarks, cp_points)
    return newImg

  }


  async updatePreState(upper: boolean) {
    // True is upper
    const value = upper ? this._lowerVal : this._upperVal;
    console.log('hello from update pre state', value)



    this._lipsCanvas = this._createNewCanvas(this._croppedLipsImg.croppedImageData, 'test')
    this._image.parentNode?.appendChild(this._lipsCanvas)

    const warper = new ImgWarper.PointDefiner(this._lipsCanvas, null, this._croppedLipsImg.croppedImageData)
    const lipsLandmarks = this._normalizePoint(this._getPointsByLandmarkIds(LIPS_LANDMARK_IDS2), this._croppedLipsImg.startPoint.x, this._croppedLipsImg.startPoint.y)


    const points = this._normalizePoint(this._createBoundaryControlPoint(this._croppedLipsImg.startPoint, this._croppedLipsImg.croppedImageData.width, this._croppedLipsImg.croppedImageData.height), this._croppedLipsImg.startPoint.x, this._croppedLipsImg.startPoint.y)

    lipsLandmarks.push(...points)



    const upL = this._getPointsByLandmarkIds([0, 13])
    const loL = this._getPointsByLandmarkIds([14, 17])

    const h1 = Math.abs(upL[0].y - upL[1].y)
    const h2 = Math.abs(loL[0].y - loL[1].y)


    const cp_points = lipsLandmarks.map((point: Point) => {
      if (!upper) {
        if (point.z === 40) return this._updatePointY(point, -value * 0.2 * h1) // 0.5 -> 0.8
        else if (point.z === 39) return this._updatePointY(point, -value * 0.2 * h1) // 0.5 -> 0.8
        else if (point.z === 37) return this._updatePointY(point, -value * 0.25 * h1)// 0.4 -> 0.7
        else if (point.z === 0) return this._updatePointY(point, -value * 0.25 * h1) // 0.5 -> 0.8
        else if (point.z === 267) return this._updatePointY(point, -value * 0.25 * h1)// 0.4 -> 0.7
        else if (point.z === 269) return this._updatePointY(point, -value * 0.2 * h1) // 0.5 -> 0.8
        else if (point.z === 270) return this._updatePointY(point, -value * 0.2 * h1)// 0.4 -> 0.7
        else if (point.z === 184) return this._updatePointY(point, -value * 0.2 * h1)// 0.4 -> 0.7
        else if (point.z === 408) return this._updatePointY(point, -value * 0.2 * h1)// 0.4 -> 0.7
        else return point

      } else {
        if (point.z === 86) return this._updatePointY(point, value * 0.2 * h2)
        else if (point.z === 316) return this._updatePointY(point, value * 0.2 * h2)
        else if (point.z === 15) return this._updatePointY(point, value * 0.3 * h2)
        else return point
      }
    });
    const newImg = warper.lift(lipsLandmarks, cp_points)

    this._ctx2D.clearRect(
      this._croppedLipsImg.startPoint.x,
      this._croppedLipsImg.startPoint.y,
      this._croppedLipsImg.croppedImageData.width,
      this._croppedLipsImg.croppedImageData.height
    );

    this._fillMissingEdge(newImg, this._croppedLipsImg.croppedImageData)

    this._ctx2D.putImageData(newImg, this._croppedLipsImg.startPoint.x, this._croppedLipsImg.startPoint.y)

    // Lưu trạng thái canvas hiện tại
    const currentImageData = this._ctx2D.getImageData(
      this._croppedLipsImg.startPoint.x,
      this._croppedLipsImg.startPoint.y,
      this._croppedLipsImg.croppedImageData.width,
      this._croppedLipsImg.croppedImageData.height
    );

    this._preCroppedLipsImg = {
      startPoint: this._croppedLipsImg.startPoint,
      croppedImageData: currentImageData
    };


  }
  async liftLips(image: any, value: number, upper: boolean) {

    if (!this._image) {
      //console.log("Image hasn't been loaded")
      return;
    }
    if (upper) {
      this._upperVal = value;
    } else {
      this._lowerVal = value;
    }

    const newImg = this._processLips(value, !this._preCroppedLipsImg ? this._croppedLipsImg : this._preCroppedLipsImg, upper)

    this._fillMissingEdge(newImg, this._croppedLipsImg.croppedImageData)

    this._removeCanvasByClassName('test')

    this._ctx2D.clearRect(
      this._croppedLipsImg.startPoint.x,
      this._croppedLipsImg.startPoint.y,
      this._croppedLipsImg.croppedImageData.width,
      this._croppedLipsImg.croppedImageData.height);

    // this._ctx2D.putImageData(currentImageData, this._croppedLipsImg.startPoint.x, this._croppedLipsImg.startPoint.y);
    this._ctx2D.putImageData(newImg, this._croppedLipsImg.startPoint.x, this._croppedLipsImg.startPoint.y)

  }


  async liftEyesBrow(value: number) {
    //console.log('day la value cua eyebrow', value)

    if (!this._image) {
      //console.log("Image hasn't been loaded")
      return;
    }

    this._eyesBrowVal = value ? value : 0;

    const [newLeftEyeBrowImg, newRightEyeBrowImg] = this._processEyesBrow(this._croppedLeftEyeBrowImg, this._croppedRightEyeBrowImg, this._eyesBrowVal)


    this._fillMissingEdge(newLeftEyeBrowImg, this._croppedLeftEyeBrowImg.croppedImageData)
    this._fillMissingEdge(newRightEyeBrowImg, this._croppedRightEyeBrowImg.croppedImageData)

    this._removeCanvasByClassName('test_left_eyeBrow')

    this._removeCanvasByClassName('test_right_eyeBrow')

    this._ctx2D.clearRect(
      this._croppedLeftEyeBrowImg.startPoint.x,
      this._croppedLeftEyeBrowImg.startPoint.y,
      this._croppedLeftEyeBrowImg.croppedImageData.width,
      this._croppedLeftEyeBrowImg.croppedImageData.height
    );

    this._ctx2D.clearRect(
      this._croppedRightEyeBrowImg.startPoint.x,
      this._croppedRightEyeBrowImg.startPoint.y,
      this._croppedRightEyeBrowImg.croppedImageData.width,
      this._croppedRightEyeBrowImg.croppedImageData.height
    );

    this._ctx2D.putImageData(newLeftEyeBrowImg, this._croppedLeftEyeBrowImg.startPoint.x, this._croppedLeftEyeBrowImg.startPoint.y)
    this._ctx2D.putImageData(newRightEyeBrowImg, this._croppedRightEyeBrowImg.startPoint.x, this._croppedRightEyeBrowImg.startPoint.y)

  }



  private _processEyesBrow(croppedLeftEyeBrowImg: CroppedImage, croppedRightEyeBrowImg: CroppedImage, value: number) {
    const leftEyeBrowCanvas = this._createNewCanvas(croppedLeftEyeBrowImg.croppedImageData, 'test_left_eyeBrow')
    const rightEyeBrowCanvas = this._createNewCanvas(croppedRightEyeBrowImg.croppedImageData, 'test_right_eyeBrow')
    this._image.parentNode?.appendChild(leftEyeBrowCanvas)
    this._image.parentNode?.appendChild(rightEyeBrowCanvas)



    const leftEyeBrowWarper = new ImgWarper.PointDefiner(leftEyeBrowCanvas, null, croppedLeftEyeBrowImg.croppedImageData)
    const rightEyeBrowWarper = new ImgWarper.PointDefiner(rightEyeBrowCanvas, null, croppedRightEyeBrowImg.croppedImageData)

    const leftEyeBrowLandmarks = this._normalizePoint(this._getPointsByLandmarkIds(leftEyesBrow_LANDMARK_IDS), croppedLeftEyeBrowImg.startPoint.x, croppedLeftEyeBrowImg.startPoint.y)
    const rightEyeBrowLandmarks = this._normalizePoint(this._getPointsByLandmarkIds(rightEyesBrow_LANDMARK_IDS), croppedRightEyeBrowImg.startPoint.x, croppedRightEyeBrowImg.startPoint.y)


    const upEyesBrow = this._getPointsByLandmarkIds([55, 282, 52, 105, 282, 334])
    const lowEyesBrow = this._getPointsByLandmarkIds([107, 334, 27, 257])

    //tính độ long may trai va phai
    const h1 = Math.abs(upEyesBrow[0].y - lowEyesBrow[0].y)




    const leftEyeBrowPoints = this._normalizePoint(this._createBoundaryControlPoint(croppedLeftEyeBrowImg.startPoint, croppedLeftEyeBrowImg.croppedImageData.width, croppedLeftEyeBrowImg.croppedImageData.height), croppedLeftEyeBrowImg.startPoint.x, croppedLeftEyeBrowImg.startPoint.y)
    const rightEyeBrowPoints = this._normalizePoint(this._createBoundaryControlPoint(croppedRightEyeBrowImg.startPoint, croppedRightEyeBrowImg.croppedImageData.width, croppedRightEyeBrowImg.croppedImageData.height), croppedRightEyeBrowImg.startPoint.x, croppedRightEyeBrowImg.startPoint.y)


    leftEyeBrowLandmarks.push(...leftEyeBrowPoints)


    rightEyeBrowLandmarks.push(...rightEyeBrowPoints)


    // 286 258 257 259 260 342 353
    const cp_right_eye_brow_point = rightEyeBrowLandmarks.map((point: Point) => {
      if (point.z === 56) return this._updatePointY(point, -value * 0.2 * h1) // 65
      if (point.z === 28) return this._updatePointY(point, -value * 0.35 * h1) // 65
      else if (point.z === 27) return this._updatePointY(point, -value * 0.6 * h1) // 0.5 -> 0.2 52
      else if (point.z === 29) return this._updatePointY(point, -value * 0.6 * h1)// 0.4 -> 0.7 53
      else if (point.z === 30) return this._updatePointY(point, -value * 0.4 * h1) // 0.5 -> 0.2 46
      else if (point.z === 113) return this._updatePointY(point, -value * 0.25 * h1)// 0.4 -> 0.7 124
      else if (point.z === 124) return this._updatePointY(point, -value * 0.2 * h1)// 0.4 -> 0.7 124
      else
        return point

    });

    const cp_left_eye_brow_point = leftEyeBrowLandmarks.map((point: Point) => {
      if (point.z === 286) return this._updatePointY(point, -value * 0.2 * h1) // 0.5 -> 0.2
      else if (point.z === 258) return this._updatePointY(point, -value * 0.35 * h1) // 0.5 -> 0.2
      else if (point.z === 257) return this._updatePointY(point, -value * 0.6 * h1)// 0.4 -> 0.7
      else if (point.z === 259) return this._updatePointY(point, -value * 0.6 * h1) // 0.5 -> 0.2
      else if (point.z === 260) return this._updatePointY(point, -value * 0.4 * h1)// 0.4 -> 0.7
      else if (point.z === 342) return this._updatePointY(point, -value * 0.25 * h1) // 0.5 -> 0.2
      else if (point.z === 353) return this._updatePointY(point, -value * 0.2 * h1)// 0.4 -> 0.7

      else
        return point
    });


    const newLeftEyeBrowImg = leftEyeBrowWarper.lift(leftEyeBrowLandmarks, cp_left_eye_brow_point)
    const newRightEyeBrowImg = rightEyeBrowWarper.lift(rightEyeBrowLandmarks, cp_right_eye_brow_point)


    return [newLeftEyeBrowImg, newRightEyeBrowImg]


  }

  private _createBoundaryControlPoint(startPoint: Point, width: number, height: number) {

    const point: Point[] = []
    for (let j = 1; j <= height; j += 20) {
      let x = startPoint.x
      const y = startPoint.y + j
      const z = -1;
      point.push(new Point(x, y, z))
      x = startPoint.x + width
      point.push(new Point(x, y, z))
    }
    for (let i = 1; i <= width; i += 20) {
      const x = startPoint.x + i;
      let y = startPoint.y;
      const z = -1;
      point.push(new Point(x, y, z))
      y = startPoint.y + height
      point.push(new Point(x, y, z))
    }

    // console.log('length point', point.length)
    return point

  }


  private _fillMissingEdge(newImg: ImageData, oldImg: ImageData) {
    if (newImg.width !== oldImg.width || newImg.height !== oldImg.height) {
      //console.log("Image size of new image and old image must be the same")
      return;
    }
    const width = oldImg.width;
    const height = oldImg.height;

    for (let i = 0; i < width * 4; i += 4) {
      newImg.data[i] = oldImg.data[i]
      newImg.data[i + 1] = oldImg.data[i + 1]
      newImg.data[i + 2] = oldImg.data[i + 2]
      newImg.data[i + 3] = oldImg.data[i + 3]

      newImg.data[i + 4 * (height - 1) * width] = oldImg.data[i + 4 * (height - 1) * width]
      newImg.data[i + 4 * (height - 1) * width + 1] = oldImg.data[i + 4 * (height - 1) * width + 1]
      newImg.data[i + 4 * (height - 1) * width + 2] = oldImg.data[i + 4 * (height - 1) * width + 2]
      newImg.data[i + 4 * (height - 1) * width + 3] = oldImg.data[i + 4 * (height - 1) * width + 3]
    }
    for (let j = 0; j < height; j += 1) {
      const s = j * width * 4
      const e = ((j + 1) * width - 1) * 4

      for (let i = 0; i < 4; i++) {
        if (newImg.data[s + i] !== oldImg.data[s + i]) {
          newImg.data[s + i] = oldImg.data[s + i]
        }
      }

      newImg.data[e] = oldImg.data[e]
      newImg.data[e + 1] = oldImg.data[e + 1]
      newImg.data[e + 2] = oldImg.data[e + 2]
      newImg.data[e + 3] = oldImg.data[e + 3]

    }

    return newImg;

  }

  async uploadImageToCanvas() {

    const image = document.getElementById('face-image') as HTMLImageElement;
    this._image = image

    const faceLandmarker = await this._createFaceLandmarker();

    if (!faceLandmarker) {
      //console.log('Wait for faceLandmarker to load before clicking!');
      return -1;
    }
    this._faceLandmarkerResult = faceLandmarker.detect(image)
    if (this._faceLandmarkerResult.faceLandmarks.length === 0) {
      return -2;
    }
    //console.log('face landmarker res', this._faceLandmarkerResult)

    this._removeAllCanvas()
    this._canvas = this._createNewCanvas(image, 'canvas absolute')
    this._canvas.style.zIndex = '1'
    image.parentNode?.appendChild(this._canvas)
    const canvasContainer = document.getElementById('faceImageCanvasContainer') as HTMLElement
    const canvasWidth = canvasContainer.offsetWidth;
    const canvasHeight = canvasContainer.offsetHeight;
    const ctx = this._canvas.getContext('2d') as CanvasRenderingContext2D
    this._ctx2D = ctx;




    const imageRatio = image.naturalWidth / image.naturalHeight;
    const canvasRatio = canvasWidth / canvasHeight;

    ctx.drawImage(image as HTMLImageElement, 0, 0, image.naturalWidth, image.naturalHeight)
    if (imageRatio > canvasRatio) {
        // Image is wider than the canvas
        this._canvas.style.width = `${Math.floor(canvasWidth)}px`;
    } else {
        // Image is taller than the canvas
        this._canvas.style.height = `${Math.floor(canvasHeight)}px`;
    }


    image.style.display = 'none';

    this._croppedLipsImg = this._cropLips();
    const cropEyesBrow = this._cropEyesBrow();
    this._croppedLeftEyeBrowImg = cropEyesBrow.leftEyeBrow;
    this._croppedRightEyeBrowImg = cropEyesBrow.rightEyeBrow;

    this._horizontalCanvas = document.createElement('canvas') as HTMLCanvasElement
    this._horizontalCanvas.setAttribute('class', '_horizontalCanvas')
    this._horizontalCanvas.setAttribute('width', `${image.width}px`)
    this._horizontalCanvas.setAttribute('height', `${image.height}px`)
    this._horizontalCanvas.style.position = 'absolute'
    this._horizontalCanvas.style.width = this._canvas.style.width
    this._horizontalCanvas.style.height = this._canvas.style.height
    this._horizontalCanvas.style.zIndex = '11'
    image.parentNode?.appendChild(this._horizontalCanvas)

    this._verticalCanvas = document.createElement('canvas') as HTMLCanvasElement
    this._verticalCanvas.setAttribute('class', '_verticalCanvas')
    this._verticalCanvas.setAttribute('width', `${image.width}px`)
    this._verticalCanvas.setAttribute('height', `${image.height}px`)
    this._verticalCanvas.style.position = 'absolute'
    this._verticalCanvas.style.width = this._canvas.style.width
    this._verticalCanvas.style.height = this._canvas.style.height
    this._verticalCanvas.style.zIndex = '11'
    image.parentNode?.appendChild(this._verticalCanvas)


    this._originalImageCanvas = document.createElement('canvas') as HTMLCanvasElement
    this._originalImageCanvas.setAttribute('class', '_originalImageCanvas')
    this._originalImageCanvas.setAttribute('width', `${image.width}px`)
    this._originalImageCanvas.setAttribute('height', `${image.height}px`)
    this._originalImageCanvas.style.position = 'absolute'
    this._originalImageCanvas.style.width = this._canvas.style.width
    this._originalImageCanvas.style.height = this._canvas.style.height
    this._originalImageCanvas.style.zIndex = '10'
    image.parentNode?.appendChild(this._originalImageCanvas)

    this._horizontalCanvas.style.display = 'none'
    this._verticalCanvas.style.display = 'none'
    this._originalImageCanvas.style.display = 'none'
    return 1
  }


  //sua de tat bat
  public showVerticalDividers() {
    if (!this._image) {
      return;
    }

    this._verticalCanvas.style.display = 'block'
    if(this.drewVertical){
      return;
    }

    const faceLandmarks = this._faceLandmarkerResult.faceLandmarks[0];

    const center = this._getPointByLandmarkId(197)
    const landmarkIds = [234, 226, 244, 464, 446, 454,];
    const landmarks = this._getPointsByLandmarkIds(landmarkIds)

    const base1 = 1.2 * (faceLandmarks[152].y - faceLandmarks[10].y) * this._canvas.height;
    const base2 = 1.7 * (faceLandmarks[152].y - faceLandmarks[10].y) * this._canvas.height;
    this._drawDividers(center, landmarks, base1, base2, false, this._verticalCanvas.getContext('2d'))

    // const base = this._canvas.width / 100;
    const base = Math.abs(landmarks[0].x - landmarks[landmarks.length - 1].x) / 25
    this._drawRates(landmarks, base, true, this._verticalCanvas.getContext('2d'));
    this.drewVertical = true;

  }

  // sua de tat bat
  public showHorizontalDividers() {
    if (!this._image) {
      //console.log("Image hasn't loaded")
      return;
    }
    this._horizontalCanvas.style.display = 'block'
    if(this.drewHorizontal){
      return;
    }

    const faceLandmarks = this._faceLandmarkerResult.faceLandmarks[0];




    const center = this._getPointByLandmarkId(197)
    const landmarkIds = [10, 9, 2, 152]
    const landmarks = this._getPointsByLandmarkIds(landmarkIds)

    const base1 = 1.5 * (faceLandmarks[454].x - faceLandmarks[234].x) * this._canvas.width;
    const base2 = 2 * (faceLandmarks[454].x - faceLandmarks[234].x) * this._canvas.width;
    this._drawDividers(center, landmarks, base1, base2, true, this._horizontalCanvas.getContext('2d'))

    const base = Math.abs(landmarks[0].y - landmarks[landmarks.length - 1].y) / 50
    this._drawRates(landmarks, base * 2, false, this._horizontalCanvas.getContext('2d'));
    this.drewHorizontal = true
  }

  public downloadCanvas() {
    const canvas = document.getElementsByClassName(
      'canvas'
    ) as HTMLCollectionOf<HTMLCanvasElement>;

    if (!canvas[0]) return;

    const dataUrl = canvas[0]
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    // console.log(dataUrl);

    const link = document.createElement('a');
    link.download = 'image.png';
    link.href = dataUrl;
    link.click();
    return dataUrl;
  }

  private _cropLips(): CroppedImage {
    const gridSize = 20
    const lipBoundaryRectCenterIds = [57, 2, 287, 18];
    const boundaryRectPoints = this._getPointsByLandmarkIds(lipBoundaryRectCenterIds)
    const boundaryWidth = Math.abs(boundaryRectPoints[0].x - boundaryRectPoints[2].x)
    const boundaryHeight = Math.abs(boundaryRectPoints[1].y - boundaryRectPoints[3].y)
    const x = Math.floor(1 / 2 * (boundaryRectPoints[0].x + boundaryRectPoints[2].x))
    const y = Math.floor(1 / 2 * (boundaryRectPoints[1].y + boundaryRectPoints[3].y))
    // const centerPoint = new Point(x, y, -1)
    const sw = Math.ceil(boundaryWidth / gridSize) * gridSize
    const sh = Math.ceil(boundaryHeight / gridSize) * gridSize
    // coordinate z of startPoint is unreal data (default to -1)
    const startPoint = new Point(x - sw / 2, y - sh / 2, -1)
    const lipsImageData = this._ctx2D.getImageData(startPoint.x, startPoint.y, sw, sh)

    return {
      startPoint,
      croppedImageData: lipsImageData
    }
  }

  //Hàm cắt lông mày
  private _cropEyesBrow() {
    const gridSize = 20
    const leftEyeBrowBoundaryRectCenterIds = [368, 333, 8, 348, 334];
    const rightEyeBrowBoundaryRectCenterIds = [139, 104, 8, 119, 105];


    const leftEyeBrowBoundaryRectPoints = this._getPointsByLandmarkIds(leftEyeBrowBoundaryRectCenterIds)
    const rightEyeBrowBoundaryRectPoints = this._getPointsByLandmarkIds(rightEyeBrowBoundaryRectCenterIds)

    //Tính toán tọa độ và các cạnh để cắt lông mày bên trái
    const leftEyeBrowBoundaryWidth = Math.abs(leftEyeBrowBoundaryRectPoints[0].x - leftEyeBrowBoundaryRectPoints[2].x)
    const leftEyeBrowBoundaryHeight = Math.abs(leftEyeBrowBoundaryRectPoints[1].y - leftEyeBrowBoundaryRectPoints[3].y)
    const leftEyeBrowX = Math.floor(1 / 2 * (leftEyeBrowBoundaryRectPoints[0].x + leftEyeBrowBoundaryRectPoints[2].x))
    const leftEyeBrowY = Math.floor(1 / 2 * (leftEyeBrowBoundaryRectPoints[1].y + leftEyeBrowBoundaryRectPoints[3].y))

    const sw = Math.ceil(leftEyeBrowBoundaryWidth / gridSize) * gridSize
    const sh = Math.ceil(leftEyeBrowBoundaryHeight / gridSize) * gridSize

    const leftEyeBrowStartPoint = new Point(leftEyeBrowX - sw / 2, leftEyeBrowY - sh / 2, -1)
    const leftEyeBrowImageData = this._ctx2D.getImageData(leftEyeBrowStartPoint.x, leftEyeBrowStartPoint.y, sw, sh)

    //Tính toán tọa độ và các cạnh để cắt lông mày bên phải
    const rightEyeBrowBoundaryWidth = Math.abs(rightEyeBrowBoundaryRectPoints[0].x - rightEyeBrowBoundaryRectPoints[2].x)
    const rightEyeBrowBoundaryHeight = Math.abs(rightEyeBrowBoundaryRectPoints[1].y - rightEyeBrowBoundaryRectPoints[3].y)
    const rightEyeBrowX = Math.floor(1 / 2 * (rightEyeBrowBoundaryRectPoints[0].x + rightEyeBrowBoundaryRectPoints[2].x))
    const rightEyeBrow = Math.floor(1 / 2 * (rightEyeBrowBoundaryRectPoints[1].y + rightEyeBrowBoundaryRectPoints[3].y))

    const right_sw = Math.ceil(rightEyeBrowBoundaryWidth / gridSize) * gridSize
    const right_sh = Math.ceil(rightEyeBrowBoundaryHeight / gridSize) * gridSize

    const rightEyeBrowStartPoint = new Point(rightEyeBrowX - right_sw / 2, rightEyeBrow - right_sh / 2, -1)
    const rightEyeBrowImageData = this._ctx2D.getImageData(rightEyeBrowStartPoint.x, rightEyeBrowStartPoint.y, right_sw, right_sh)

    return {
      leftEyeBrow: {
        startPoint: leftEyeBrowStartPoint,
        croppedImageData: leftEyeBrowImageData
      },
      rightEyeBrow: {
        startPoint: rightEyeBrowStartPoint,
        croppedImageData: rightEyeBrowImageData
      }
    };

  }


  private _createNewCanvas(image: HTMLImageElement | ImageData, className: string) {
    let naturalW = image.width;
    let naturalH = image.height;
    if (image instanceof HTMLImageElement) {
      naturalW = image.naturalWidth;
      naturalH = image.naturalHeight
    }
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.setAttribute('class', className)
    canvas.setAttribute('width', `${naturalW}px`)
    canvas.setAttribute('height', `${naturalH}px`)
    canvas.style.width = `${image.width}px`;
    canvas.style.height = `${image.height}px`
    return canvas
  }

  private _removeAllCanvas() {
    // Remove all landmarks drawn before
    const parentNode = this._image.parentNode as HTMLDivElement;
    const allCanvas = parentNode.getElementsByClassName('canvas') as HTMLCollectionOf<HTMLCanvasElement>;
    for (let i = allCanvas.length - 1; i >= 0; i--) {
      const n = allCanvas[i];
      n.parentNode?.removeChild(n);
    }
  }

  private _removeCanvasByClassName(className: string) {
    const canvas = document.getElementsByClassName(className);
    if (canvas.length === 0) {
      return;
    }
    for (let i = canvas.length - 1; i >= 0; i--) {
      const n = canvas[i];
      n.parentNode?.removeChild(n);
    }
  }

  private async _createFaceLandmarker() {
    // const demosSection = document.getElementById('demos') as HTMLDivElement;

    const filesetResolver = await FilesetResolver.forVisionTasks(
      'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm'
    );

    // demosSection.classList.remove('invisible');

    return await FaceLandmarker.createFromOptions(
      filesetResolver,
      {
        baseOptions: {
          modelAssetPath: '../../../assets/models/face_landmarker.task',
          delegate: 'GPU',
        },
        outputFaceBlendshapes: true,
        runningMode: 'IMAGE',
        numFaces: 1,
      }
    );
  }

  private _getPointsByLandmarkIds(ids: number[]) {
    const points: Point[] = []
    const width = this._canvas.width
    const height = this._canvas.height
    const faceLandmarks = this._faceLandmarkerResult.faceLandmarks[0]
    for (const id of ids) {
      const x = Math.floor(faceLandmarks[id].x * width);
      const y = Math.floor(faceLandmarks[id].y * height);
      points.push(new Point(x, y, id))
    }
    // console.log(`getPointsByLandmarkIds: `)
    // for(var landMark of points){
    //   console.log(`x: ${landMark.x}
    //   y: ${landMark.y}
    //   z: ${landMark.z}`)
    // }
    return points
  }

  private _getPointByLandmarkId(id: number) {
    const width = this._canvas.width
    const height = this._canvas.height
    const faceLandmark = this._faceLandmarkerResult.faceLandmarks[0][id];
    const x = faceLandmark.x * width;
    const y = faceLandmark.y * height;
    const z = faceLandmark.z
    return new Point(x, y, z)
  }

  private _calculateHorizontalPoint (center: Point, point: Point, base1: number, base2: number) {
    let dis1 = center.y - point.y;
    let sign = 1;

    if (dis1 < 0) {
      dis1 = -dis1;
      sign = -1;
    }

    const dis2 = (dis1 * base2) / base1;
    const point1 = new Point(point.x + base1 / 2, point.y, point.z);
    const point2 = new Point(point.x - base1 / 2, point.y, point.z);
    const point3 = new Point(center.x - base2 / 2, center.y - sign * dis2, center.z);
    const point4 = new Point(center.x + base2 / 2, center.y - sign * dis2, center.z);

    return [point1, point2, point3, point4];
  }

  private _calculateVerticalDividerPoints(center: Point, point: Point, base1: number, base2: number) {
    let dis1 = center.x - point.x;
    let sign = 1;

    if (dis1 < 0) {
      dis1 = -dis1;
      sign = -1;
    }

    const dis2 = (dis1 * base2) / base1;
    const point1 = new Point(point.x, point.y + base1 / 2, point.z);
    const point2 = new Point(point.x, point.y - base1 / 2, point.z);
    const point3 = new Point(center.x - sign * dis2, center.y - base2 / 2, center.z);
    const point4 = new Point(center.x - sign * dis2, center.y + base2 / 2, center.z);

    return [point1, point2, point3, point4];
  }

  private _drawDividers(center: Point, landmarks: Point[], base1: number, base2: number, isVertical = true, upperCtx: any) {
    // const upperCtx = this._upperCanvas.getContext('2d')
    for (const landmark of landmarks) {
      let points: Point[];
      if (isVertical) {
        points = this._calculateHorizontalPoint(center, landmark, base1, base2);
      } else {
        points = this._calculateVerticalDividerPoints(center, landmark, base1, base2)
      }

      upperCtx.beginPath();
      upperCtx.moveTo(points[0].x, points[0].y);
      for (let i = 1; i < points.length; i++) {
        upperCtx.lineTo(points[i].x, points[i].y);
      }
      upperCtx.closePath();
      upperCtx.strokeStyle = 'rgba(0, 0, 0, 0.2)';
      upperCtx.fillStyle = 'rgba(0, 0, 0,0.25)';
      // ctx.scale(1, 4);
      upperCtx.stroke();
      upperCtx.fill();

    }
  }

  private _drawRates(landmarks: Point[], fontSize: number, isVertical = true, upperCtx: any) {
    console.log('9999',this._canvas.width, this._canvas.height)
    const rates = this._calculateRateOfFace(landmarks, isVertical);
    upperCtx.strokeStyle = 'rgba(255,255,255)';
    upperCtx.fillStyle = 'rgba(255, 255, 255)';
    upperCtx.font = `bold ${fontSize}pt Calibri`;
    upperCtx.textAlign = 'center';
    let pixel = 100;
    if(!isVertical){
      for (const rate of rates) {
        upperCtx.fillText(`${rate.text}%`, rate.x, rate.y);
      }
    }else {
      for (const rate of rates) {
        upperCtx.fillText(`${rate.text}%`, rate.x, rate.y + pixel);
        pixel*=-1;
      }
    }

  }

  private _calculateRateOfFace(points: Point[], vertical: boolean) {
    const rates: Text[] = [];

    if (vertical) {
      const max_length = Math.abs(points[0].x - points[points.length - 1].x);
      for (let i = 0; i < points.length - 1; i++) {
        const len = Math.abs(points[i].x - points[i + 1].x);
        const rate = (len * 100) / max_length;
        const y = points[i].y;
        const x = (points[i].x + points[i + 1].x) / 2;
        rates.push({ text: String(rate.toFixed(1)), x, y });
      }
      return rates;
    }

    const max_length = Math.abs(points[0].y - points[points.length - 1].y);
    for (let i = 0; i < points.length - 1; i++) {
      const len = Math.abs(points[i].y - points[i + 1].y);
      const rate = (len * 100) / max_length;
      const x = points[i].x;
      const y = (points[i].y + points[i + 1].y) / 2;
      rates.push({ text: String(rate.toFixed(1)), x, y });
    }
    return rates;
  }

  //sua de tat bat
  async drawFaceMesh() {
    this._ctx2D.reset()
    this._ctx2D.drawImage(this._image, 0, 0, this._image.naturalWidth, this._image.naturalHeight)

    const drawingUtils = new DrawingUtils(this._ctx2D);
    for (const landmarks of this._faceLandmarkerResult.faceLandmarks) {
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_TESSELATION,
        { color: '#C0C0C070', lineWidth: 1 }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
        { color: '#FF3030' }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
        { color: '#FF3030' }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
        { color: '#30FF30' }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
        { color: '#30FF30' }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
        { color: '#E0E0E0' }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LIPS,
        {
          color: '#E0E0E0',
        }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
        { color: '#FF3030' }
      );
      drawingUtils.drawConnectors(
        landmarks,
        FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
        { color: '#30FF30' }
      );
    }
    console.log('face landmarks left eyebrow', FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW)
    // this._cropLips()
  }

  //Hàm reset ảnh về ảnh gốc
  async resetImg(){
    this.resetUpperCanvas()
    this._lowerVal = 0
    this._upperVal = 0
    this._eyesBrowVal = 0
    this.drewHorizontal = false
    this.drewVertical = false
    this._ctx2D.reset()
    this._ctx2D.drawImage(this._image, 0, 0, this._image.naturalWidth, this._image.naturalHeight)
  }

  async resetUpperCanvas(){
    const upperCtx = this._horizontalCanvas.getContext('2d') as CanvasRenderingContext2D
    if (upperCtx) {
      // Clear the entire canvas
      upperCtx.clearRect(0, 0, this._horizontalCanvas.width, this._horizontalCanvas.height);

      // Reset transformations (identity matrix)
      upperCtx.setTransform(1, 0, 0, 1, 0, 0);

      // reset other context properties to their default values
      upperCtx.globalAlpha = 1.0;
      upperCtx.globalCompositeOperation = 'source-over';
      upperCtx.fillStyle = '#000000';
      upperCtx.strokeStyle = '#000000';
      upperCtx.lineWidth = 1;
      upperCtx.lineCap = 'butt';
      upperCtx.lineJoin = 'miter';
      upperCtx.miterLimit = 10;
      upperCtx.shadowBlur = 0;
      upperCtx.shadowColor = 'rgba(0, 0, 0, 0)';
      upperCtx.shadowOffsetX = 0;
      upperCtx.shadowOffsetY = 0;
      upperCtx.font = '10px sans-serif';
      upperCtx.textAlign = 'start';
      upperCtx.textBaseline = 'alphabetic';
    }
    const upperCtx2 = this._verticalCanvas.getContext('2d') as CanvasRenderingContext2D
    if (upperCtx2) {
      // Clear the entire canvas
      upperCtx2.clearRect(0, 0, this._horizontalCanvas.width, this._horizontalCanvas.height);

      // Reset transformations (identity matrix)
      upperCtx2.setTransform(1, 0, 0, 1, 0, 0);

      // reset other context properties to their default values
      upperCtx2.globalAlpha = 1.0;
      upperCtx2.globalCompositeOperation = 'source-over';
      upperCtx2.fillStyle = '#000000';
      upperCtx2.strokeStyle = '#000000';
      upperCtx2.lineWidth = 1;
      upperCtx2.lineCap = 'butt';
      upperCtx2.lineJoin = 'miter';
      upperCtx2.miterLimit = 10;
      upperCtx2.shadowBlur = 0;
      upperCtx2.shadowColor = 'rgba(0, 0, 0, 0)';
      upperCtx2.shadowOffsetX = 0;
      upperCtx2.shadowOffsetY = 0;
      upperCtx2.font = '10px sans-serif';
      upperCtx2.textAlign = 'start';
      upperCtx2.textBaseline = 'alphabetic';
    }
  }
  turnOffUpperCanvas(){
    this._horizontalCanvas.style.display = 'none'
    this._verticalCanvas.style.display = 'none'

  }
  getImageFromCanvas() {
    const imageDataUrl: string = this._canvas.toDataURL('image/jpg');
    let upperCanvasUrl = null;
    let originalImageUrl = null;
    if(this._horizontalCanvas.style.display != 'none') {
      upperCanvasUrl = this._horizontalCanvas.toDataURL('image/png')
    } else if(this._verticalCanvas.style.display != 'none') {
      upperCanvasUrl = this._verticalCanvas.toDataURL('image/png')
    }

    if(this._originalImageCanvas.style.display != 'none')
      originalImageUrl = this._originalImageCanvas.toDataURL('image/jpg')

    return {lowerUrl: imageDataUrl,
            originalImageUrl: originalImageUrl,
            upperUrl: upperCanvasUrl,
            imageWidth: this._image.width,
            imageHeight: this._image.height}
  }

  showOriginalImage(scale: number){
    const context: CanvasRenderingContext2D = this._originalImageCanvas.getContext('2d') as CanvasRenderingContext2D;
    context.clearRect(0, 0, this._originalImageCanvas.width, this._originalImageCanvas.height);
    scale = scale/100
    const canvasContainer = document.getElementById('faceImageCanvasContainer') as HTMLElement

    let divWidth = canvasContainer.offsetWidth
    const canvasWidth = parseFloat(this._originalImageCanvas.style.width.replace('px',''));
    const realCanvasWidth = this._originalImageCanvas.width
    const scaleX = canvasWidth / realCanvasWidth


    divWidth = divWidth / scaleX
    const blankWidth = divWidth - realCanvasWidth
    let destWidth = divWidth * scale
    if(destWidth > blankWidth/2){
      destWidth = destWidth - blankWidth/2
      context.drawImage(this._image, 0, 0, destWidth, this._image.naturalHeight, 0, 0, destWidth, this._originalImageCanvas.height)
    }
  }

  handleOnOriginalImageSlider(onOriginalImageSlider: boolean){
    if(onOriginalImageSlider){
      this._originalImageCanvas.style.display = 'block'
    }else {
      this._originalImageCanvas.style.display = 'none'
    }

  }
}
